<template>
  <div class="account">
    <h2>账号绑定</h2>
    <div class="item-phone d-f j-b a-c">
      <div class="phone-detail d-f a-c">
        <img src="@/assets/image/user_account_phone.png" alt="" />
        <div class="bind">
          <h3>手机号已绑定</h3>
          <p>您可以享受到手机相关的登录，安全及提醒服务</p>
        </div>
        <div class="num">{{ phone }}</div>
      </div>
      <a-button type="primary" @click="repairPhone"> 更改手机号 </a-button>
    </div>
    <div class="item-wx d-f j-b a-c">
      <div class="wx-detail d-f a-c">
        <img src="@/assets/image/user_account_wx.png" alt="" />
        <div class="bind">
          <h3 v-if="hasBind">微信号已绑定</h3>
          <h3 v-else>微信号未绑定</h3>
          <p>绑定后，您可以直接使用微信扫码登录网站</p>
        </div>
      </div>
      <a-button type="primary" @click="unbindWx" v-if="hasBind"> 解除绑定 </a-button>
      <a-button type="primary" @click="bindWx" v-else> 绑定微信 </a-button>
    </div>

    <!-- 修改手机号 对话框 -->
    <a-modal
      cancelText="关闭"
      okText="下一步"
      v-model="visible"
      title="修改手机号"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="phone" label="手机号">
          <a-input v-model="form.phone" placeholder="请输入新手机号"> </a-input>
        </a-form-model-item>
        <a-form-model-item
          prop="smsCode"
          label="验证码"
          :wrapper-col="{ span: 11, offset: 0 }"
          class="repair-phone-model"
        >
          <a-input v-model="form.smsCode" placeholder="请输入验证码"> </a-input>
          <span class="code">
            <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
            <a-button v-if="!isSend" disabled>{{ count }}s后重新获取</a-button>
          </span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 绑定微信 对话框 -->
    <a-modal
      v-model="visibleWx"
      title="绑定微信"
      :footer="null"
      width="300px"
      @cancel="bindWxCancel"
    >
      <div class="bind-wx-con d-f j-c">
        <div class="qrcode-container" ref="qrCodeContainer"></div>
        <div class="dust d-f f-d a-c j-c" v-if="needUpdate">
          <h4>二维码已失效</h4>
          <a-button type="primary" @click="bindWx"> 点击刷新 </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {
  sendSmsCode,
  verifyCode,
  getUserInfo,
  unbundingWx,
  bundingWx,
  isBindSuccess,
} from "@/axios/acl.js";
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    return {
      visible: false,
      phone: "",
      id: "",
      form: {},
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: "blur",
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callbackFn("手机号码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: "blur",
          },
        ],
        smsCode: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: "blur",
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^\d{4}$/;
              if (!reg.test(value)) {
                callbackFn("验证码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: "blur",
          },
        ],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      count: 60,
      isSend: true,
      timer: null,
      visible: false, //控制修改手机对话框
      hasBind: false, //账号是否绑定微信
      visibleWx: false, //控制绑定微信对话框
      uuid: "",
      timer1: null,
      needUpdate: false,
    };
  },
  methods: {
    //修改手机号打开弹框
    repairPhone() {
      this.visible = true;
    },
    //修改手机号下一步
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          verifyCode("web/sms/verifyCode", {
            phone: this.form.phone,
            smsCode: this.form.smsCode,
            smsCodeType: 4,
          }).then((res) => {
            if (res.success) {
              this.visible = false;
              this.$info({
                title: "修改成功，请重新登录",
                onOk: () => {
                  window.open("https://cloud.jsxniu.com/login", "_self");
                  this.$ls.set("userInfo", null);
                  this.$ls.set("token", null);
                },
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //发送验证码新手机
    sendVerifyCode() {
      this.$refs.ruleForm.validateField("phone", (valid) => {
        if (!valid) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            sendSmsCode("web/sms/smsCode", {
              phone: this.form.phone,
              smsCodeType: 4,
            }).then((res) => {
              this.$message.info(res.message);
              if (res.success) {
                return;
              } else {
                this.$message.error(res.message);
              }
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //修改手机号取消
    handleCancel() {
      this.visible = false;
      this.form = {};
      clearInterval(this.timer);
      this.isSend = true;
      this.visible = false;
      this.timer = null;
    },
    //解绑微信
    unbindWx() {
      this.$confirm({
        title: "你确定解绑吗",
        onOk: () => {
          unbundingWx("acl/user/unbundingWx/", { userId: this.id }).then((res) => {
            // console.log(res);
            if (res.success) {
              this.$message.success("解绑成功");
              getUserInfo("acl/index/info").then((res) => {
                if (res.success) {
                  this.$ls.set("userInfo", res.data, 60 * 60 * 1000 * 24);
                  if (this.$ls.get("userInfo").openId != null) {
                    this.hasBind = true;
                  } else {
                    this.hasBind = false;
                  }
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          });
        },
      });
    },
    //绑定微信
    bindWx() {
      this.visibleWx = true;
      this.needUpdate = false;

      let uuid = uuidv4().replace(/-/g, "");
      bundingWx("acl/wx/bindWxScan/", { uuid: uuid, userId: this.id }).then((res) => {
        if (res.success) {
          this.$refs.qrCodeContainer.innerHTML = "";
          this.bindQRCode(res.data.url);
          this.timer1 = setInterval(() => {
            this.isBindSuccess(uuid);
          }, 2000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //绑定微信取消
    bindWxCancel() {
      this.$refs.qrCodeContainer.innerHTML = "";
      clearInterval(this.timer1);
    },
    //生成二维码
    bindQRCode(url) {
      new QRCode(this.$refs.qrCodeContainer, {
        text: url,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //查看微信扫码状态
    isBindSuccess(uuid) {
      isBindSuccess("acl/wx/isBindSuccess/", { uuid: uuid }).then((res) => {
        console.log(res.data.ret);
        if (res.data.ret === 3) {
          getUserInfo("acl/index/info").then((result) => {
            if (result.success) {
              this.$ls.set("userInfo", result.data, 60 * 60 * 1000 * 24);
              if (this.$ls.get("userInfo").openId != null) {
                this.hasBind = true;
              } else {
                this.hasBind = false;
              }
              this.$message.success("绑定成功");
              this.visibleWx = false;
            }
          });
          this.$refs.qrCodeContainer.innerHTML = "";
          clearInterval(this.timer1);
        } else if (res.data.ret === 2) {
          this.needUpdate = true;
          clearInterval(this.timer1);
        }
      });
    },
  },
  created() {
    this.phone = this.$ls.get("userInfo").phone;
    this.id = this.$ls.get("userInfo").id;
    if (this.$ls.get("userInfo").openId != null) {
      this.hasBind = true;
    } else {
      this.hasBind = false;
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timer1);
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.account {
  width: 936px;
  height: 710px;
  background: #fff;
  padding-left: 36px;
  padding-top: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
  }
  .item-phone {
    margin-top: 48px;
    width: 864px;
    height: 120px;
    background: #f6f6f6;
    opacity: 1;
    border-radius: 4px;
    padding: 0 24px;
    box-sizing: border-box;
    .phone-detail {
      img {
        width: 48px;
      }
      .bind {
        margin-left: 36px;
        h3 {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-top: 12px;
          margin-bottom: 0;
        }
      }
      .num {
        margin-left: 119px;
        color: @main-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .item-wx {
    margin-top: 24px;
    width: 864px;
    height: 120px;
    background: #f6f6f6;
    opacity: 1;
    border-radius: 4px;
    padding: 0 24px;
    box-sizing: border-box;
    .wx-detail {
      img {
        width: 48px;
      }
      .bind {
        margin-left: 36px;
        h3 {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-top: 12px;
          margin-bottom: 0;
        }
      }
      .num {
        margin-left: 143px;
        color: @main-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.repair-phone-model {
  .code {
    position: absolute;
    top: -11px;
  }
}
.bind-wx-con {
  width: 100%;
  position: relative;
  min-height: 150px;
  .dust {
    width: 140px;
    height: 140px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    h4 {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>
